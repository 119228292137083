import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '../views/dashboard.vue'
import InventoryDashboard from '../views/inventory/dashboard.vue'
import POSDashboard from '../views/pos/dashboard.vue'
import ClientPOSDashboard from '../views/client-pos/dashboard.vue'
import HRMDashboard from '../views/hrm/dashboard.vue'
import BookingsDashboard from '../views/bookings/dashboard.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Main',
    component: () => import(/* webpackChunkName: "main" */ '../views/main.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/settings.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "help" */ '../views/help.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/company-staff-management',
        name: 'companyStaffManagement',
        component: () => import(/* webpackChunkName: "company-staff-management" */ '../views/Users.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/warehouses',
        name: 'Warehouses',
        component: () => import(/* webpackChunkName: "warehouses" */ '../views/warehouses.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/suppliers',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "newsletters" */ '../views/suppliers.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/categories',
        name: 'Categories',
        component: () => import(/* webpackChunkName: "categories" */ '../views/categories.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/category/:categoryID/:categoryName/products',       
        name: 'Categories',
        component: () => import(/* webpackChunkName: "products" */ '../views/category-products.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/category/:categoryID/:categoryName/add-product',
        name: 'AddProduct',
        component: () => import(/* webpackChunkName: "add-product" */ '../views/add-product.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/category/:categoryID/:categoryName/edit-product/:productID',
        name: 'EditProduct',
        component: () => import(/* webpackChunkName: "edit-product" */ '../views/edit-product.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/sales',
        name: 'Sales',
        component: () => import(/* webpackChunkName: "sales" */ '../views/sales.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "products" */ '../views/products.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/transactions',
        name: 'Transactions',
        component: () => import(/* webpackChunkName: "newsletters" */ '../views/transactions.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/purchases',
        name: 'Purchases',
        component: () => import(/* webpackChunkName: "purchases" */ '../views/purchases.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/newsletters',
        name: 'Newsletters',
        component: () => import(/* webpackChunkName: "newsletters" */ '../views/newsletters.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/branches',
        name: 'Branches',
        component: () => import(/* webpackChunkName: "branches" */ '../views/branches.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/menu-categories',
        name: 'MenuCategories',
        component: () => import(/* webpackChunkName: "menu-categories" */ '../views/pos/menu-categories.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/menu-category/:CategoryID/:menuCategoryName/menu-items',       
        name: 'MenuCategories',
        component: () => import(/* webpackChunkName: "menu-items" */ '../views/pos/menu-items.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/menu-category/:CategoryID/:menuCategoryName/add-menu-item',
        name: 'AddMenuItem',
        component: () => import(/* webpackChunkName: "add-menu-item" */ '../views/pos/add-menu-item.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/menu-category/:CategoryID/:menuCategoryName/edit-menu-item/:MenuItemID',
        name: 'EditMenuItem',
        component: () => import(/* webpackChunkName: "edit-menu-item" */ '../views/pos/edit-menu-item.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import(/* webpackChunkName: "orders" */ '../views/pos/orders.vue'),
        meta: { requiresLogin: true }
      },
      // {
      //   path: '/customers',
      //   name: 'Customers',
      //   component: () => import(/* webpackChunkName: "customers" */ '../views/orders.vue'),
      //   meta: { requiresLogin: true }
      // },
      // {
      //   path: '/transactions',
      //   name: 'Transactions',
      //   component: () => import(/* webpackChunkName: "transactions" */ '../views/orders.vue'),
      //   meta: { requiresLogin: true }
      // },

    ]
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: InventoryDashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "inventory-home" */ '../views/inventory/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/warehouses',
        name: 'Warehouses',
        component: () => import(/* webpackChunkName: "warehouses" */ '../views/warehouses.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/suppliers',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "newsletters" */ '../views/suppliers.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/categories',
        name: 'Categories',
        component: () => import(/* webpackChunkName: "categories" */ '../views/categories.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/category/:categoryID/:categoryName/products',       
        name: 'Categories',
        component: () => import(/* webpackChunkName: "products" */ '../views/category-products.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/category/:categoryID/:categoryName/add-product',
        name: 'AddProduct',
        component: () => import(/* webpackChunkName: "add-product" */ '../views/add-product.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/category/:categoryID/:categoryName/edit-product/:productID',
        name: 'EditProduct',
        component: () => import(/* webpackChunkName: "edit-product" */ '../views/edit-product.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/sales',
        name: 'Sales',
        component: () => import(/* webpackChunkName: "sales" */ '../views/sales.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "products" */ '../views/products.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/transactions',
        name: 'Transactions',
        component: () => import(/* webpackChunkName: "newsletters" */ '../views/transactions.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/purchases',
        name: 'Purchases',
        component: () => import(/* webpackChunkName: "purchases" */ '../views/purchases.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/settings.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/inventory/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "help" */ '../views/help.vue'),
        meta: { requiresLogin: true }
      },

    ]
  },
  {
    path: '/POS',
    name: 'POSDashboard',
    component: POSDashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'POSHome',
        component: () => import(/* webpackChunkName: "pos-home" */ '../views/pos/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/POS/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/settings.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/POS/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "help" */ '../views/help.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/POS/menu-categories',
        name: 'MenuCategories',
        component: () => import(/* webpackChunkName: "menu-categories" */ '../views/pos/menu-categories.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/POS/menu-category/:CategoryID/:menuCategoryName/menu-items',       
        name: 'MenuCategories',
        component: () => import(/* webpackChunkName: "menu-items" */ '../views/pos/menu-items.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/POS/menu-category/:CategoryID/:menuCategoryName/add-menu-item',
        name: 'AddMenuItem',
        component: () => import(/* webpackChunkName: "add-menu-item" */ '../views/pos/add-menu-item.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/POS/menu-category/:CategoryID/:menuCategoryName/edit-menu-item/:MenuItemID',
        name: 'EditMenuItem',
        component: () => import(/* webpackChunkName: "edit-menu-item" */ '../views/pos/edit-menu-item.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/POS/orders',
        name: 'Orders',
        component: () => import(/* webpackChunkName: "orders" */ '../views/pos/orders.vue'),
        meta: { requiresLogin: true }
      },
      // {
      //   path: '/POS/customers',
      //   name: 'Customers',
      //   component: () => import(/* webpackChunkName: "customers" */ '../views/pos/orders.vue'),
      //   meta: { requiresLogin: true }
      // },
      // {
      //   path: '/POS/transactions',
      //   name: 'Transactions',
      //   component: () => import(/* webpackChunkName: "transactions" */ '../views/pos/orders.vue'),
      //   meta: { requiresLogin: true }
      // },

    ]
  },
  {
    path: '/ClientPOS',
    name: 'ClientPOSDashboard',
    component: ClientPOSDashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'ClientPOSHome',
        component: () => import(/* webpackChunkName: "pos-home" */ '../views/client-pos/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ClientPOS/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/settings.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ClientPOS/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "help" */ '../views/help.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ClientPOS/menu-categories',
        name: 'MenuCategories',
        component: () => import(/* webpackChunkName: "menu-categories" */ '../views/pos/menu-categories.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ClientPOS/menu-category-items/:CategoryID/:categoryName',
        name: 'MenuCategoryItems',
        component: () => import(/* webpackChunkName: "menu-category-items " */ '../views/client-pos/menu-category-items.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/ClientPOS/manage-my-orders',
        name: 'ManageMyOrders',
        component: () => import(/* webpackChunkName: "manage-my-orders" */ '../views/client-pos/manage-my-orders.vue'),
      },
      {
        path: '/ClientPOS/manage-my-order-items',
        name: 'ManageMyOrderItems',
        component: () => import(/* webpackChunkName: "manage-my-order-items" */ '../views/client-pos/manage-my-order-items.vue'),
      }
    ]
  },
  {
    path: '/HRM',
    name: 'HRMDashboard',
    component: HRMDashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "hrm-home" */ '../views/hrm/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/HRM/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/settings.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/HRM/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "help" */ '../views/help.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/HRM/branches',
        name: 'Branches',
        component: () => import(/* webpackChunkName: "branches" */ '../views/branches.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/HRM/company-staff-management',
        name: 'companyStaffManagement',
        component: () => import(/* webpackChunkName: "company-staff-management" */ '../views/Users.vue'),
        meta: { requiresLogin: true }
      },

    ]
  },
  {
    path: '/bookings&reservations',
    name: 'BookingsDashboard',
    component: BookingsDashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Bookings-home" */ '../views/bookings/Home.vue'),
        meta: { requiresLogin: true }
      },

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },

  //In case the route is anything else
  {
    path: '*',
    redirect: '/dashboard/home'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  if (to.name === "Login" && localStorage.getItem('user')) {
    next({ path: "/" });
  }

  //Check if login is required for Routes with meta data loginRequired!
  if (to.matched.some(record => record.meta.requiresLogin)) {
    if (!localStorage.getItem('user')) {
      //Redirect to Login Page
      next({ path: "/login" });
    } else next();
  } else next();
});

export default router
