<template>
  <div class="sidebar-section pb-5">
    <div>
        <div class="company-desc">
          <div>
            <div class="compamy-logo">
              <img
                :src="$store.state.company.company_logo"
                style="width: 100px;"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
          <h1>
            {{ $store.state.company.company_title }}
          </h1>
        </div>

        <div class="side-link">
          <ul>
            <li
              style="padding-right:5px"
              @click="$router.push({path: '/'})"
              :class="{ active: $route.name == 'Home' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-menu"
              ></i> Dashboard
            </li>

            <li
              style="padding-right:5px"
              @click="$router.push({path: '/HRM/branches'})"
              :class="{ active: $route.name == 'Branches' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-menu"
              ></i> Branches
            </li>

            <li
              @click="$router.push({path: '/HRM/company-staff-management'})"
              :class="{ active: $route.name == 'companyStaffManagement' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-operation"
              ></i> Company Staff Management
            </li>
          </ul>
        </div>
    </div>

        
    <div id="sidebar-article" style="padding-top: 100px;">
      <div style="">
        <div class="side-link">
          <ul>
            
            <li
              style="padding-right:5px"
              @click="$router.push({path: 'settings'})"
              :class="{ active: $route.name == 'Settings' }"
            >
              <span><i class="fas fa-cog"></i> Settings</span>
            </li>
            <li
              style="padding-right:5px"
              @click="$router.push({path: 'settings'})"
              :class="{ active: $route.name == 'Settings' }"
            >
              <span><i class="fas fa-cog"></i>/Support</span>
            </li>


            <li @click="logout">
              <i class="fas fa-sign-out-alt"></i> Logout
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeNames: ['1']
    };
  },

  methods: {
      handleChange(val) {
        console.log(val);
      },
    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
      location.reload(true);
    },
  },
};
</script>

<style scoped>
.sidebar-section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
}
.sidebar-section > div{
  width: 100%;
}

.company-desc {
  padding: 20px 20px;
  border: 1px solid rgb(2, 149, 149);
  cursor: pointer;
}

.company-desc >div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-desc h1{
 font-size: 1.5em;
 font-weight: 600;
 text-align: center;
}

.compamy-logo {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
}
.company-desc .compamy-logo img{
  width: 130px !important;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.side-link ul {
  list-style: none;
  padding: 0px;
}
.side-link ul li {
  border-top: 1px solid rgb(2, 149, 149);
  padding: 10px;
  cursor: pointer;
  transition: 0.5s;
  font-size: 1.0em;
  font-weight: 500;
}
.side-link .active {
  border-left: 6px solid rgb(18, 194, 194);
  color: rgb(193, 240, 240) !important;
  font-weight: 600;
}
.side-link ul li:hover {
  padding-left: 30px;
  transition: 0.5s;
  color: rgb(193, 240, 240);
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
}
</style>

<style>
  .el-collapse-item__header {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 48px;
    line-height: 48px;
    background-color: teal;
    color: white;
    cursor: pointer;
    border-top: 1px solid rgb(2, 149, 149);
    border-bottom: none;
    font-size: 1.0em;
    font-weight: 500;
    transition: border-top-color .3s;
    outline: 0;
  }

  .side-link ul li {
    border-top: 1px solid rgb(2, 149, 149);
    padding-top: 30px;
    padding-bottom: 10px;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 400;
    background-color: teal;
    color: rgb(138, 204, 204);
    color: whitesmoke
}


.el-collapse-item__content {
    padding-bottom: 25px;
  font-size: 1.0em;
    color: #303133;
    line-height: 1.769230769230769;
    background-color: teal;
}
</style>
