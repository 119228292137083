<template>
    <div>
      <el-dialog
              title="Create Payment"
              :visible.sync="isEditCategoryModalVisible"
              width="60%"
              :before-close="handleClose">
              
              <el-form :model="paymentForm" :rules="rules" label-position="top" ref="paymentForm" label-width="120px" class="demo-paymentForm">

                <el-form-item label="Choose Customer Type" prop="TableOrRoom">
                  <el-select v-model="paymentForm.TableOrRoom" placeholder="Choose Customer Type">
                    <el-option label="walk-in-customer" value="walk-in-customer"></el-option>
                    <el-option label="Hotel Room 1" value="Hotel Room 1"></el-option>
                    <el-option label="Hotel Room 2" value="Hotel Room 2"></el-option>
                  </el-select>
                </el-form-item>

                <div class="create-payment-grid">
                  <div class="">
                    <el-form-item label="Received Amount" prop="name">
                      <el-input v-model="paymentForm.ReceivedAmount"></el-input>
                    </el-form-item>
                    <el-form-item label="Paying Amount" prop="name">
                      <el-input v-model="orderPricingData.TotalAmount"></el-input>
                    </el-form-item>

                    <p class="mt-4">Change Return : {{ formatNumber(Number(paymentForm.ReceivedAmount) - Number(orderPricingData.TotalAmount)) }}</p>
                  </div>
                  <div class="">
                    <ul>
                      <li>Total Products: <span>{{ orderList.length }}</span></li>
                      <li>TaxRate: <span>{{ orderPricingData.TaxRate }}</span></li>
                      <li>Discount: <span>{{ formatNumber(orderPricingData.Discount) }}</span></li>
                      <li>Shipping: <span>{{ formatNumber(orderPricingData.Shipping) }}</span></li>
                      <li>Total Payable: <span>{{ formatNumber(orderPricingData.TotalAmount) }}</span></li>
                    </ul>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Payment choice" prop="PaymentChoice">
                      <el-select v-model="paymentForm.PaymentChoice" placeholder="Payment choice ">
                        <el-option label="Cash" value="Cash"></el-option>
                        <el-option label="Credit Card" value="Credit Card"></el-option>
                        <el-option label="Cheque" value="Cheque"></el-option>
                        <el-option label="Western Union" value="Western Union"></el-option>
                        <el-option label="Bank Transfer" value="Bank Transfer"></el-option>
                        <el-option label="Other" value="Other"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-6"></div>
                </div>
                
                <div class="row">
                  <div class="col-md-6">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4}"
                      placeholder="Payment Notes"
                      v-model="paymentForm.PaymentNotes">
                    </el-input>
                  </div>
                  <div class="col-md-6">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4}"
                      placeholder="Sales Notes"
                      v-model="paymentForm.SalesNotes">
                    </el-input>
                  </div>
                </div>

                <el-form-item>
                  <el-button type="primary" @click="submitForm('paymentForm')">Submit</el-button>
                </el-form-item>
              </el-form>
            </el-dialog>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    
        
    computed: {
        ...mapGetters(['orderList', 'totalPrice', 'orderPricing']),
        orderPricingData() {
          return this.orderPricing;
        }
    },

    data() {
      return {
        loading: false,
        // dialogVisible: false
        isEditCategoryModalVisible: false,
        
        paymentForm: {
          ReceivedAmount: '',
          TableOrRoom: '',
          PaymentChoice: '',
          PaymentNotes: '',
          SalesNotes: '',
        },
        rules: {
          ReceivedAmount: [
            { required: true, message: 'Please input ReceivedAmount', trigger: 'blur' },
            // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
          ],
          TableOrRoom: [
            { required: true, message: 'Please Select Customer Type', trigger: 'change' }
          ],
          PaymentChoice: [
            { required: true, message: 'Please Select Payment Choice', trigger: 'change' }
          ],
          PaymentNotes: [
            { required: true, message: 'Please input Payment Notes', trigger: 'blur' }
          ],
          SalesNotes: [
            { required: true, message: 'Please input Sales Notes', trigger: 'blur' }
          ]
        }
      };
    },
  
    props: {
      showEditCategoryDialog: {
        required: true,
        type: Boolean,
      },
    },
  
    watch: {
      showEditCategoryDialog() {
        if (this.showEditCategoryDialog === true) {
          this.isEditCategoryModalVisible = true;
        } else {
          this.isEditCategoryModalVisible = false;
          this.$emit("closeModal");
        }
      },
    },
  
    methods: {

      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      },

      handleClose(done) {
        done();
        this.showEditCategoryDialog = false;
        this.$emit("closeModal");
      },

      

      async sendOrder() {
          // alert("orderList" + JSON.stringify(this.orderList))
          // this.$refs[formName].validate(async (valid) => {

          //   if (valid) {


              
              try {
                this.submitting = true;
                let response = await this.$http.post(`menu-orders`, {
                  TableOrRoom: 'Table 50',
                  TotalAmount: this.totalPrice,
                  AddedBy: this.$store.state.userId,
                  CompanyID: this.$store.state.company.company_id,
                  BranchID: 1,
                  OrderListItems: JSON.stringify(this.orderList),
                  TaxRate: this.taxRate,
                  Discount: this.discount,
                  Shipping: this.shipping,

                });
                if (
                  response.data.success &&
                  response.data.message == 'Order and order items inserted successfully'
                ) {
                  // this.$refs[formName].resetFields();
                  // this.$emit("re-fresh");
                  this.$notify({
                    title: "Success",
                    message: "Order and order items inserted successfully",
                    type: "success",
                  });
                  this.clearOrderList();
                  this.taxRate = 0;
                  this.discount = 0;
                  this.shipping = 0;
                } else {
                  throw "UNEXPECTED_RESPONSE";
                }
              } catch (error) {
                if (error.message == "Network Error") {
                  return this.$notify({
                    title: "Connection failed",
                    message: "A network error occurred, please try again.",
                    type: "error",
                  });
                }
                this.$notify({
                  title: "Unable to Add Menu Order",
                  message: "An unexpected Error occurred, please try again",
                  type: "error",
                });
              } finally {
                this.submitting = false;
              }



          //   } else {
          //     return false;
          //   }

          // });
        },

      
      submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            
            try {
                this.submitting = true;
                let response = await this.$http.post(`menu-orders`, {
                  TableOrRoom: this.paymentForm.TableOrRoom,
                  PaymentChoice: this.paymentForm.PaymentChoice,
                  ReceivedAmount: this.paymentForm.ReceivedAmount,
                  PaymentNotes: this.paymentForm.PaymentNotes,
                  SalesNotes: this.paymentForm.SalesNotes,
                  TotalAmount: Number(this.orderPricingData.TotalAmount),
                  AddedBy: this.$store.state.userId,
                  CompanyID: this.$store.state.company.company_id,
                  BranchID: 1,
                  OrderListItems: JSON.stringify(this.orderList),
                  TaxRate: Number(this.orderPricingData.TaxRate),
                  Discount: Number(this.orderPricingData.Discount),
                  Shipping: Number(this.orderPricingData.Shipping),

                });
                if (
                  response.data.success &&
                  response.data.message == 'Order and order items inserted successfully'
                ) {
                  // this.$refs[formName].resetFields();
                  // this.$emit("re-fresh");
                  this.$notify({
                    title: "Success",
                    message: "Order and order items inserted successfully",
                    type: "success",
                  });
                  this.clearOrderList();
                  this.taxRate = 0;
                  this.discount = 0;
                  this.shipping = 0;
                } else {
                  throw "UNEXPECTED_RESPONSE";
                }
              } catch (error) {
                if (error.message == "Network Error") {
                  return this.$notify({
                    title: "Connection failed",
                    message: "A network error occurred, please try again.",
                    type: "error",
                  });
                }
                this.$notify({
                  title: "Unable to Add Menu Order",
                  message: "An unexpected Error occurred, please try again",
                  type: "error",
                });
              } finally {
                this.submitting = false;
              }

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
  };
  </script>

<style scoped>
  .create-payment-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .create-payment-grid >div {
    /* border: 1px solid black; */
  }

  
  
  .create-payment-grid >div:nth-child(2) {
    border: 1px solid #DFE1E5;
    padding: 20px;
  }

  .create-payment-grid ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  .create-payment-grid  ul li {
    padding: 10px;
    border: 1px solid #DFE1E5;
    border-radius: 5px;
     margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>

<style>
.el-dialog {
    border: 10px solid teal !important;
}
.el-select {
  width: 100%;
}

.el-form--label-top .el-form-item__label {
    padding: 0 0 0px;
} 

.el-form-item__label {
    line-height: 40px;
    padding: 0 0px 0 0;
}
label {
    margin-bottom: 0rem;
}

.el-input__inner{
    margin-bottom: 10px !important;
}

.el-textarea__inner{
    margin-bottom: 50px !important;
}
</style>