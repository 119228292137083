<template>

<div>
    <div class="the-payable-section">
        <div>
            <article class="order-list-section">
                <div>
                  <div class="d-flex align-items-center justify-content-between">
                      <h2>Order List</h2>
                      <span><small>{{ $store.state.currentCurrency }}</small></span>
                  </div>
                        <ul class="responsive-table">
                        <li class="table-header">
                            <div class="p-2">Item</div>
                            <div class="p-2">Price & Qty</div>
                            <div class="p-2"></div>
                        </li>
                        <!-- {{ orderList }} -->
                        <li class="table-row" v-for="item in orderList" :key="item.MenuItemID">
                            <div class="">
                              <img :src="item.ItemImage" style="width: 40px; height: 40px; border-radius: 50%; object-fit: cover;">
                            </div>
                            <div class="">
                              <div>
                                <small>{{ formatCurrency(item.Price) }}</small><br/>
                                <el-input-number
                                  v-model="item.Quantity"
                                  :min="1"
                                  :max="1000000"
                                  size="mini"
                                  @change="handleChange(item.MenuItemID, item.Quantity)"
                                ></el-input-number>
                              </div>
                            </div>
                            <div class=""> 
                              <button class="remove-btn" @click="removeItem(item.MenuItemID)"><i class="el-icon-delete-solid"></i></button>
                              <!-- <el-button type="danger" @click="removeItem(item.MenuItemID)" icon="el-icon-delete" circle></el-button> -->
                            </div>
                        </li>
                        </ul>
                </div>
                <div class="payment-article">
                    
                </div>
            </article> 
        </div>

        <div>
            <div class="text-center" style="background-color: #7EC8CA; padding: 10px; font-size: 1.2em;">
                <strong>Total Payable : {{ formatCurrency(totalPrice) }}</strong>
            </div>
            <div class="p-2 tax-discount-shipping">
              <div>
                <strong>Tax</strong>
                <el-input
                  v-model.number="taxRate"
                  size="mini"
                  placeholder="0%"
                  @change="handleTaxRateChange"
                ></el-input>
              </div>
              <div>
                <strong>Discount</strong>
                <el-input
                  size="mini"
                  placeholder="0"
                  v-model.number="discount"
                  @change="handleDiscountChange"
                ></el-input>
              </div>
              <div>
                <strong>Shipping</strong>
                <el-input
                  v-model.number="shipping"
                  size="mini"
                  placeholder="0"
                  @change="handleShippingChange"
                ></el-input>
              </div>
                
            </div>
            <div class="payment-buttons">
                <div @click="openModal( totalPrice, taxRate, discount, shipping)">Send Order</div>
                <div>Card Payment</div>
                <!-- @click="sendOrder" -->
            </div>
        </div>
    </div>

    </div>
</template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
// import OrderPaymentDialog from "../dialogs/menu/orders/order-payment-dialog.vue";
  export default {
      components: {
        // OrderPaymentDialog,
      },
        
    computed: {
        ...mapGetters(['orderList', 'totalPrice']),
        
      taxRate: {
        get() {
          return this.$store.state.taxRate;
        },
        set(value) {
          this.$store.dispatch('setTaxRate', value);
        }
      },
      discount: {
        get() {
          return this.$store.state.discount;
        },
        set(value) {
          this.$store.dispatch('setDiscount', value);
        }
      },
      shipping: {
        get() {
          return this.$store.state.shipping;
        },
        set(value) {
          this.$store.dispatch('setShipping', value);
        }
      },
    
    },
    
    data() {
      return {
        showEditCategoryDialog: false,
          
          current_currency: '',
          country: '',
          currency_code: '',
          kes_rate: '',
          tzs_rate: '',
          ssp_rate: '',
          rwf_rate: '',
          bif_rate: '',
          ugx_rate: '',
          usd_rate: '',
      };
    },

    
  
    watch: {
      totalPrice() {
       this.handleTotalAmountChange(this.totalPrice)
      },
    },
    
    mounted() {
        this.loadOrderList();
    },
  
    methods: {
        ...mapActions(['loadOrderList', 'addItemToOrderList', 'removeItem', 'updateItemQuantity', 'clearOrderList', 'setTaxRate', 
                        'setDiscount', 'setShipping', 'updateTotalAmount', 'updateTaxRate', 'updateDiscount', 'updateShipping', 'updateOrderPricing']),

        handleChange(itemId, Quantity) {
          this.updateItemQuantity({ itemId, Quantity });
        },

        handleTotalAmountChange(TotalAmount) {
          this.updateTotalAmount({ TotalAmount });
        },

        handleTaxRateChange(TaxRate) {
          this.updateTaxRate({ TaxRate });
        },

        handleDiscountChange(Discount) {
          this.updateDiscount({ Discount });
        },

        handleShippingChange(Shipping) {
          this.updateShipping({ Shipping });
        },

        async sendOrder() {
          // alert("orderList" + JSON.stringify(this.orderList))
          // this.$refs[formName].validate(async (valid) => {

          //   if (valid) {


              
              try {
                this.submitting = true;
                let response = await this.$http.post(`menu-orders`, {
                  TableOrRoom: 'Table 50',
                  TotalAmount: this.totalPrice,
                  AddedBy: this.$store.state.userId,
                  CompanyID: this.$store.state.company.company_id,
                  BranchID: 1,
                  OrderListItems: JSON.stringify(this.orderList),
                  TaxRate: this.taxRate,
                  Discount: this.discount,
                  Shipping: this.shipping,

                });
                if (
                  response.data.success &&
                  response.data.message == 'Order and order items inserted successfully'
                ) {
                  // this.$refs[formName].resetFields();
                  // this.$emit("re-fresh");
                  this.$notify({
                    title: "Success",
                    message: "Order and order items inserted successfully",
                    type: "success",
                  });
                  this.clearOrderList();
                  this.taxRate = 0;
                  this.discount = 0;
                  this.shipping = 0;
                } else {
                  throw "UNEXPECTED_RESPONSE";
                }
              } catch (error) {
                if (error.message == "Network Error") {
                  return this.$notify({
                    title: "Connection failed",
                    message: "A network error occurred, please try again.",
                    type: "error",
                  });
                }
                this.$notify({
                  title: "Unable to Add Menu Order",
                  message: "An unexpected Error occurred, please try again",
                  type: "error",
                });
              } finally {
                this.submitting = false;
              }



          //   } else {
          //     return false;
          //   }

          // });
        },


        openModal( totalPrice, taxRate, discount, shipping) {
          // Retrieve the existing order Pricing from local storage or initialize an empty array
          const orderPricing = {
            TotalAmount: this.totalPrice,
            TaxRate: this.taxRate,
            Discount: this.discount,
            Shipping: this.shipping,
          };

          // Store the updated order Pricing back to local storage
          localStorage.setItem('orderPricing', JSON.stringify(orderPricing));

          this.updateOrderPricing({ totalPrice, taxRate, discount, shipping });
          this.$emit('openModal')
        },

        
      closeModal() {
          this.$emit('closeModal')
      },

      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      },

      formatCurrency(amount) {
        const currency = this.$store.state.currentCurrency
        // currency = 'USD';

        if (currency === 'KES') {
          const newAmount = Math.round((this.kes_rate / this.ugx_rate) * amount)
          return `KES ${this.formatNumber(newAmount)}`
        } else if (currency === 'UGX') {
          return `UGX ${this.formatNumber(amount)}`
        } else if (currency === 'TZS') {
          const newAmount = Math.round((this.tzs_rate / this.ugx_rate) * amount)
          return `TZS ${this.formatNumber(newAmount)}`
        } else if (currency === 'USD') {
          const newAmount = Math.round((this.usd_rate / this.ugx_rate) * amount)
          if (newAmount <= 1) {
            return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(3)}`
          }
          return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(2)}`
        } else if (currency === 'SSP') {
          const newAmount = Math.round((this.ssp_rate / this.ugx_rate) * amount)
          return `SSP ${this.formatNumber(newAmount)}`
        } else if (currency === 'RWF') {
          const newAmount = Math.round((this.rwf_rate / this.ugx_rate) * amount)
          return `RWF ${this.formatNumber(newAmount)}`
        } else if (currency === 'BIF') {
          const newAmount = Math.round((this.bif_rate / this.ugx_rate) * amount)
          return `BIF ${this.formatNumber(newAmount)}`
        } else {
          // default to UGX
          return `UGX ${this.formatNumber(amount)}`
        }
      },

      async getCurrencyRates() {
        try {
          const request = await this.$axios.get(
            'latest.json?app_id=8f4b23638b764037902c6e67e559a0d0', {
              baseURL: 'https://openexchangerates.org/api/'
            }
          )
          if (request.data) {
            this.currencyData = request.data
            // value for 1 dollar
            this.usd_rate = 1
            this.ugx_rate = this.currencyData.rates.UGX
            this.kes_rate = this.currencyData.rates.KES
            this.tzs_rate = this.currencyData.rates.TZS
            this.ssp_rate = this.currencyData.rates.SSP
            this.rwf_rate = this.currencyData.rates.RWF
            this.bif_rate = this.currencyData.rates.BIF
            this.isFetching = false
            this.isError = false
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
          this.isFetching = true
          this.isError = true
        } finally {
          this.loading = false
        }
      },
    },
  };
  </script>
  
  <style scoped>
    .the-payable-section {
      background-color: #d9f4f4;
        min-height: 100vh;
        width: 100%;
    }
    .the-payable-section > div:first-child{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: #fff;
        position: fixed;
        top: 0px;
        bottom: 180px;
        right: 0px;
        left: 75%;
        /* width: 100%; */
        overflow: auto;
        padding: 10px;
    }
    .the-payable-section > div:nth-child(2){
        height: 180px;
        position: fixed;
        bottom: 0px;
        right: 0px;
        left: 75%;
        /* width: 100%; */

        
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    }

    .payment-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .payment-buttons > div{
        cursor: pointer;
        border: 1px solid black;
        text-align: center;
        padding: 10px 5px;
    }
    .payment-buttons > div:first-child{
        background-color: green;
    }
    .payment-buttons > div:nth-child(2){
        background-color: grey;
    }

    .remove-btn {
      color: red;
      border-radius: 50%;
      border: 1px solid #F5F7FA;
      cursor: pointer;
    }
    

    
h2 {
  font-size: 1.2em;
  font-weight: 600;
  margin: 20px 0;
}
.responsive-table {
  padding: 0px !important;
}

.responsive-table li {
  border-radius: 3px;
  display: grid;
  grid-template-columns: 20% 70% 10%;

  margin-left: 0px !important;
  margin-bottom: 10px;
}
.responsive-table li>div {
  border: 1px solid #F5F7FA;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.responsive-table .table-header {
  background-color: #95A5A6;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.responsive-table .table-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}

.tax-discount-shipping {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
  </style>