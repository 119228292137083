<template>
    <div class="home_flex">
      <div class="home_grid">
  
        <section>
  
          <SidebarComponent />
  
          <!-- <div style="cursor: pointer">
            <div style="
                          margin-bottom: 10px;
                          display: flex;
                          align-items: center;
                          justify-content: center;">
              <img
                :src="$store.state.logoImageUrl"
                style="width: 100px;"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
          <div class="side-link">
            <ul>
              <li
                style="padding-right:5px"
                @click="$router.push({path: '/'})"
                :class="{ active: $route.name == 'Home' }"
              >
                <i
                  style="padding-right:5px"
                  class="el-icon-menu"
                ></i> Dashboard
              </li>
              <li
                style="padding-right:5px"
                @click="$router.push({path: '/categories'})"
                :class="{ 
                  active: $route.name == 'Categories' ||
                  $route.name == 'Products' ||
                  $route.name == 'AddProduct' ||
                  $route.name == 'EditProduct'||
                  $route.name == 'Colors' ||
                  $route.name == 'AddColor' ||
                  $route.name == 'EditColor', 
                }"
              >
                <i
                  style="padding-right:5px"
                  class="el-icon-s-grid"
                ></i> Categories
              </li>
              
              <li
                style="padding-right:5px"
                @click="$router.push({path: '/product-groups'})"
                :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'ProductGroups' }]"
              >
                  <i
                    style="padding-right:5px"
                    class="el-icon-share"
                  ></i> Product Groups
              </li>
  
              <li
                style="padding-right:5px"
                @click="$router.push({path: '/banners'})"
                :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'Banners' }]"
              >
                  <i
                    style="padding-right:5px"
                    class="el-icon-s-shop"
                  ></i> Banners
              </li>
  
              <li
                @click="$router.push({path: '/countries'})"
                :class="{ active: $route.name == 'Countries' }"
              >
                <i
                  style="padding-right:5px"
                  class="el-icon-s-operation"
                ></i> Countries
              </li>
  
              <li
                @click="$router.push({path: '/pickup-locations'})"
                :class="{ active: $route.name == 'PickupLocations' }"
              >
                <i
                  style="padding-right:5px"
                  class="el-icon-s-flag"
                ></i> Pick-Up Locations
              </li>
  
              <li
                style="padding-right:5px"
                @click="$router.push({path: '/orders'})"
                :class="{ active: $route.name == 'Orders' }"
              >
                <i
                  style="padding-right:5px"
                  class="el-icon-s-data"
                ></i> Orders
              </li>
  
              <li
                @click="$router.push({path: '/safari-packages'})"
                :class="{ active: $route.name == 'SafariPackages' }"
              >
                <i
                  style="padding-right:5px"
                  class="el-icon-user"
                ></i> Clients
              </li>
  
              <li
                @click="$router.push({path: '/users'})"
                :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'Users' }]"
              >
                  <i
                    style="padding-right:5px"
                    class="el-icon-user-solid"
                  ></i> Team
              </li>
  
              <li
                @click="$router.push({path: '/safari-packages'})"
                :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'SafariPackages' }]"
              >
                <i
                  style="padding-right:5px"
                  class="el-icon-s-operation"
                ></i> Transactions
              </li>
  
              <li
                @click="$router.push({path: '/newsletters'})"
                :class="{ active: $route.name == 'NewsLetters' }"
              >
                <i
                  style="padding-right:5px"
                  class="el-icon-s-operation"
                ></i> NewsLetters
              </li>
  
              <li @click="logout">
                <i
                  style="padding-right:5px"
                  class="fas fa-arrow-left"
                ></i> Logout
              </li>
  
            </ul>
          </div> -->
        </section>
        <section>
          <div class="theTopNavbar">
            <NavbarComponent />
          </div>
          <div class="container">
            <router-view />
            <!-- {{ $store.state }} -->
          </div>
          <FooterComponent />
        </section>
      </div>
    </div>
  </template>
  
  <script>
  import NavbarComponent from "../../components/navbar-component.vue";
  import FooterComponent from "../../components/footer.vue";
  import SidebarComponent from "../../components/inventory/sidebar-component.vue";
  export default {
    components: {
      NavbarComponent,
      FooterComponent,
      SidebarComponent
    },
  
    data() {
      return {
      };
    },
  
    // then, in the parent component,
    // watch the `$route` to determine the transition to use
    watch: {
      $route(to, from) {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      },
    },
  
    mounted() {
      // this.setCurrentUser();
    },
  
    methods: {
  
      logout() {
        // remove user from local storage to log user out
        localStorage.removeItem("user");
        location.reload(true);
      },
    },
  };
  </script>
  
  
  <style scoped>
  .home_grid {
    width: 100%;
  }
  
  .home_grid > section:first-child {
    width: 20%;
    height: 100vh;
    background-color: teal; 
    color: white;
  
    position: fixed;
    top: 0px;
    bottom: 0px;
    overflow: auto;
  }
  .home_grid > section:nth-child(2) {
    margin-left: 20%;
    padding-top: 60px;
    width: 80%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
  
  .side-link ul {
    list-style: none;
    padding: 0px;
  }
  .side-link ul li {
    border-bottom: 1px solid grey;
    padding-top: 30px;
    padding-bottom: 10px;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 600;
  }
  .side-link .active {
    border-left: 6px solid #ff1f54;
    color: grey !important;
    font-weight: 600;
  }
  .side-link ul li:hover {
    padding-left: 30px;
    transition: 0.5s;
    color: grey;
  }
  
  .theTopNavbar {
    position: fixed;
    top: 0px;
    left: 20%;
    right: 0px;
    z-index: 100;
  }
  
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
    .home_grid > section:first-child {
      display: none;
      width: 0px;
      height: 0px;
      padding: 0px;
      position: relative;
    }
    .home_grid > section:nth-child(2) {
      margin-left: 0px;
      padding-top: 50px;
      width: 100%;
    }
  
    .theTopNavbar {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 100;
    }
  }
  
  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
    .home_grid > section:first-child {
      display: none;
      width: 0px;
      height: 0px;
      padding: 0px;
      position: static;
    }
    .home_grid > section:nth-child(2) {
      margin-left: 0px;
      padding-top: 50px;
      width: 100%;
    }
  
    .theTopNavbar {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 100;
    }
  }
  </style>