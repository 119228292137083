import Vue from 'vue';
import Vuex from 'vuex';
import jwt_decode from "jwt-decode";
import { ecommerceStore } from './ecommerceStore';
import { INITIALIZE_STORE, LOGIN_USER } from "./mutation-types";
// import createLocalStoragePlugin from './vuex-local-storage-plugin';

Vue.use(Vuex);

// Define the type for items in orderList
interface OrderItem {
  MenuItemID: number;
  ItemImage?: string;
  Quantity?: number;
  Price?: number;
}

interface OrderPrice {
  Discount?: number;
  Shipping?: number;
  TaxRate?: number;
  TotalAmount?: number;
}

// Retrieve and parse orderList from localStorage
const orderListString = localStorage.getItem('orderList');
const orderList: OrderItem[] = orderListString ? JSON.parse(orderListString) as OrderItem[] : [];

// Retrieve and parse orderPricing from localStorage
const orderPricingString = localStorage.getItem('orderPricing');
const orderPricing: OrderPrice = orderPricingString ? JSON.parse(orderPricingString) as OrderPrice : {};

export default new Vuex.Store({
  state: {
    accessToken: "",
    userId: "",
    currentEmail: "user@email.com",
    firstName: "",
    fullName: "Full Name",
    profilePicture: "",
    userRoles: "Role",
    logoImageUrl: "assets/images/eglogo3.png",
    academicLevel: "",
    company: {},
    counter: 0,
    orderList: orderList,
    orderPricing: orderPricing,
      
    taxRate: 0, // Tax rate in percentage
    discount: 0, // Discount amount
    shipping: 0, // Shipping cost

    currentCurrency: 'UGX',
  },

  mutations: {
    [LOGIN_USER]: (state, { accessToken }) => {
      try {
        const decoded: any = jwt_decode(accessToken);
        state.userId = decoded.result[0].user_id;
        state.profilePicture = decoded.result[0].profile_picture;
        state.firstName = decoded.result[0].first_name;
        state.userRoles = decoded.result[0].roles;
        state.company = decoded.result[0].company;
        state.accessToken = accessToken;
        state.fullName = `${decoded.result[0].first_name} ${decoded.result[0].last_name}`;
        state.currentEmail = `${decoded.result[0].email}`;
      } catch (error) {
        // Handle the error appropriately
      }

      localStorage.setItem("user", accessToken);
    },

    [INITIALIZE_STORE]: (state) => {
      const accessToken = localStorage.getItem("user");
      if (!accessToken) return localStorage.removeItem("user");
      try {
        const decoded: any = jwt_decode(accessToken);
        state.userId = decoded.result[0].user_id;
        state.accessToken = accessToken;
        state.profilePicture = decoded.result[0].profile_picture;
        state.firstName = decoded.result[0].first_name;
        state.userRoles = decoded.result[0].roles;
        state.company = decoded.result[0].company;
        state.fullName = `${decoded.result[0].first_name} ${decoded.result[0].last_name}`;
        state.currentEmail = `${decoded.result[0].email}`;
      } catch (error) {
        // Handle the error appropriately
      }
    },

    incrementCounter(state) {
      state.counter++;
    },

    increase(state, payload) {
      state.counter = state.counter + payload.value;
    },

    setOrderList(state, orderList: OrderItem[]) {
      state.orderList = orderList;
      localStorage.setItem('orderList', JSON.stringify(orderList));
    },

    addItem(state, MenuItem: OrderItem) {
      const orderList = state.orderList || [];
      const existingItem = orderList.find(item => item.MenuItemID === MenuItem.MenuItemID);

      if (existingItem) {
        existingItem.Quantity = (existingItem.Quantity || 0) + 1;
      } else {
        orderList.push({
          ItemImage: MenuItem.ItemImage,
          MenuItemID: MenuItem.MenuItemID,
          Quantity: 1,
          Price: MenuItem.Price
        });
      }
      localStorage.setItem('orderList', JSON.stringify(state.orderList));
    },

    REMOVE_ITEM(state, MenuItemID: number) {
      state.orderList = state.orderList.filter(item => item.MenuItemID !== MenuItemID);
      localStorage.setItem('orderList', JSON.stringify(state.orderList));
    },

    UPDATE_ITEM_QUANTITY(state, { itemId, Quantity }: { itemId: number, Quantity: number }) {
      const item = state.orderList.find(item => item.MenuItemID === itemId);
      if (item) {
        item.Quantity = Quantity;
        localStorage.setItem('orderList', JSON.stringify(state.orderList));
      }
    },

    UPDATE_TOTAL_AMOUNT(state, { TotalAmount }: { TotalAmount : number }) {
        const item = state.orderPricing;
        item.TotalAmount = TotalAmount;
        localStorage.setItem('orderPricing', JSON.stringify(state.orderPricing));
    },

    UPDATE_TAXRATE(state, { TaxRate }: { TaxRate : number }) {
        const item = state.orderPricing;
        item.TaxRate = TaxRate;
        localStorage.setItem('orderPricing', JSON.stringify(state.orderPricing));
    },

    UPDATE_DISCOUNT(state, { Discount }: { Discount : number }) {
        const item = state.orderPricing;
        item.Discount = Discount;
        localStorage.setItem('orderPricing', JSON.stringify(state.orderPricing));
    },

    UPDATE_SHIPPING(state, { Shipping }: { Shipping : number }) {
        const item = state.orderPricing;
        item.Shipping = Shipping;
        localStorage.setItem('orderPricing', JSON.stringify(state.orderPricing));
    },

    UPDATE_ORDER_PRICING(state, { TotalAmount, TaxRate, Discount, Shipping }: { TotalAmount: number, TaxRate: number, Discount: number, Shipping: number }) {
      // const item = state.orderPricing;
      localStorage.setItem('orderPricing', JSON.stringify(state.orderPricing));
    },

    CLEAR_ORDER_LIST(state) {
      state.orderList = [];
      localStorage.removeItem('orderList');
    },

    SET_TAX_RATE(state, taxRate: number) {
      state.taxRate = taxRate;
    },

    SET_DISCOUNT(state, discount: number) {
      state.discount = discount;
    },

    SET_SHIPPING(state, shipping: number) {
      state.shipping = shipping;
    },

    
    SET_CURRENT_CURRENCY(state, payload) {
      state.currentCurrency = payload;
    },
  },

  actions: {
    incrementCounter(context) {
      context.commit("incrementCounter");
    },

    increase(context, payload) {
      context.commit("increase", payload);
    },

    initializeStore(context) {
      context.commit("initializeStore");
    },

    loadOrderList({ commit }) {
      const orderList = JSON.parse(localStorage.getItem('orderList') || '[]') as OrderItem[];
      commit('setOrderList', orderList);
    },

    addItemToOrderList({ commit }, MenuItem: OrderItem) {
      commit('addItem', MenuItem);
    },

    removeItem({ commit }, MenuItemID: number) {
      commit('REMOVE_ITEM', MenuItemID);
    },

    updateItemQuantity({ commit }, payload: { itemId: number, Quantity: number }) {
      commit('UPDATE_ITEM_QUANTITY', payload);
    },

    updateTotalAmount({ commit }, TotalAmount: number) {
      commit('UPDATE_TOTAL_AMOUNT', TotalAmount);
    },

    updateTaxRate({ commit }, TaxRate: number) {
      commit('UPDATE_TAXRATE', TaxRate);
    },

    updateDiscount({ commit }, Discount: number) {
      commit('UPDATE_DISCOUNT', Discount);
    },

    updateShipping({ commit }, Shipping: number) {
      commit('UPDATE_SHIPPING', Shipping);
    },

    updateOrderPricing({ commit }, payload: { TotalAmount: number, TaxRate: number, Discount: number, Shipping: number }) {
      commit('UPDATE_ORDER_PRICING', payload);
    },

    clearOrderList({ commit }) {
      commit('CLEAR_ORDER_LIST');
    }, 
    
    

    setTaxRate({ commit }, taxRate: number) {
      commit('SET_TAX_RATE', taxRate);
    },

    setDiscount({ commit }, discount: number) {
      commit('SET_DISCOUNT', discount);
    },

    setShipping({ commit }, shipping: number) {
      commit('SET_SHIPPING', shipping);
    },
  },

  getters: {
    another(state) {
      return state.counter * 100;
    },

    checkingAnother(state, getters) {
      if (getters.another < 10000) {
        return "We are less";
      } else if (getters.another === 10000) {
        return "We are Medium";
      } else {
        return "We are Great";
      }
    },

    isQualityAssurance: state => {
      return state.userRoles.includes('QA');
    },

    orderList: state => state.orderList,
    orderPricing: state => state.orderPricing,


    subtotal: state => {
      return state.orderList.reduce((total, item) => {
        return total + ((item.Price ?? 0) * (item.Quantity ?? 1));
      }, 0);
    },

    totalPrice: (state, getters) => {
      const subtotal = getters.subtotal;
      const taxAmount = (state.taxRate / 100) * subtotal;
      const totalPrice = subtotal + taxAmount - state.discount + state.shipping;
      return totalPrice;
    },

    
    getCurrentCurrency: state => state.currentCurrency,

    // totalPrice: (state) => {
    //   return state.orderList.reduce((total, item) => {
    //     return total + ((item.Price ?? 0) * (item.Quantity ?? 1));
    //   }, 0);
    // }
    
  },

  plugins: [ecommerceStore],
  modules: {}
});
