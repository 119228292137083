<template>
  <div class="sidebar-section pb-5">
    <div>
        <div class="company-desc">
          <div>
            <div class="compamy-logo">
              <img
                :src="$store.state.company.company_logo"
                style="width: 100px;"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
          <h1>
            {{ $store.state.company.company_title }}
          </h1>
        </div>

        <div class="side-link">
          <!-- {{ categories }} -->
          <ul>
            <li
              style="padding-right:5px"
              v-for="category in categories" 
              :key="category.CategoryID"
              @click="$router.push( { path: `/ClientPOS/menu-category-items/${category.CategoryID}/${category.categoryName}` } )"
            >
              <img :src="category.categoryImage" style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"> 
              <span> {{ category.categoryName }}</span> 
              <small> ({{ category.quantityAvailable }} Items)</small>
            </li>
            <!-- <li
              @click="$router.push({path: '/POS/menu-categories'})"
              :class="{ active: $route.name == 'MenuCategories' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-operation"
              ></i> Menu Categories
            </li> -->
            <!-- <li
              @click="$router.push({path: '/POS/Orders'})"
              :class="{ active: $route.name == 'Orders' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-operation"
              ></i> Orders
            </li>
            <li
              @click="$router.push({path: '/POS/customers'})"
              :class="{ active: $route.name == 'Customers' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-operation"
              ></i> Customers
            </li> -->
            <!-- <li
              @click="$router.push({path: '/POS/menu-transactions'})"
              :class="{ active: $route.name == 'MenuTransactions' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-operation"
              ></i> Menu Transactions
            </li> -->
          </ul>
        </div>
    </div>

        
    <div id="sidebar-article" style="padding-top: 100px;">
      <div style="">
        <div class="side-link">
          <ul>
            <li
              style="padding-right:5px"
              @click="$router.push({path:'/ClientPOS/manage-my-orders'})"
              :class="{ active: $route.name == 'Settings' }"
            >
              <span><i class="fas fa-cog"></i> Manage My Orders</span>
            </li>
            <li
              style="padding-right:5px"
              @click="$router.push({path:'/ClientPOS/settings'})"
              :class="{ active: $route.name == 'Settings' }"
            >
              <span><i class="fas fa-cog"></i> Settings</span>
            </li>
            <li
              style="padding-right:5px"
              @click="$router.push({path: '/ClientPOS/help'})"
              :class="{ active: $route.name == 'Settings' }"
            >
              <span><i class="fas fa-cog"></i>/Support</span>
            </li>
            <li @click="logout">
              <i class="fas fa-sign-out-alt"></i> Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      isLoading: false,
      is_changing: false,
      loadingError: false,
      categories: [],
    };
  },

  mounted() {
    this.getCategories();
  },

  methods: {

    async getCategories() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`menu-categories/${this.$store.state.company.company_id}`);
        if (request.data.success) {
          this.categories = request.data.categories;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch categories now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
      location.reload(true);
    },
  },
};
</script>

<style scoped>
.sidebar-section {
  background-color: teal;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
}
.sidebar-section > div{
  width: 100%;
}

.company-desc {
  padding: 20px 20px;
  border: 1px solid rgb(2, 149, 149);
  cursor: pointer;
}

.company-desc >div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-desc h1{
 font-size: 1.2em;
 font-weight: 600;
 text-align: center;
}

.compamy-logo {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
}
.company-desc .compamy-logo img{
  width: 130px !important;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.side-link ul {
  list-style: none;
  padding: 0px;
}
.side-link ul li {
  border-top: 1px solid rgb(2, 149, 149);
  padding: 10px;
  cursor: pointer;
  transition: 0.5s;
  font-size: 1.0em;
  font-weight: 500;
}
.side-link .active {
  border-left: 6px solid rgb(18, 194, 194);
  color: rgb(193, 240, 240) !important;
  font-weight: 600;
}
.side-link ul li:hover {
  padding-left: 30px;
  transition: 0.5s;
  color: rgb(193, 240, 240);
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
}
</style>