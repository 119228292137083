/* eslint-disable no-unused-vars */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// export const ecommerceStore = store => {
//     store.dispatch('initializeStore')
// }

export const ecommerceStore = store => {
    store.commit('INITIALIZE_STORE')
}

