<template>
    <div class="home_flex">
      <div class="home_grid">
        <section>
          <POSSidebarComponent />
        </section>
        <section>
          <div class="theTopNavbar">
            <NavbarComponent />
          </div>
          <div>
            
        <!-- <el-button type="text" @click="openModal">click to open the Dialog</el-button> -->
            <OrderPaymentDialog :showEditCategoryDialog="showEditCategoryDialog" @closeModal="closeModal"></OrderPaymentDialog>
            <router-view />
          </div>
          <FooterComponent />
        </section>
        <section>
          <OrderListComponent @openModal="openModal" @closeModal="closeModal"/>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  import NavbarComponent from "../../components/navbar-component.vue";
  import FooterComponent from "../../components/footer.vue";
  import POSSidebarComponent from "../../components/client-pos/sidebar-component.vue";
  import OrderListComponent from "../../components/client-pos/order-list-component.vue";
import OrderPaymentDialog from "../../components/dialogs/menu/orders/order-payment-dialog.vue";
  export default {
    components: {
      NavbarComponent,
      FooterComponent,
      POSSidebarComponent,
      OrderListComponent,
    OrderPaymentDialog
    },
  
    data() {
      return {
        showEditCategoryDialog:false,
      };
    },
  
    // then, in the parent component,
    // watch the `$route` to determine the transition to use
    watch: {
      $route(to, from) {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      },
    },
  
    mounted() {
      // this.setCurrentUser();
    },
  
    methods: {
      openModal() {
        this.showEditCategoryDialog = true
      },
      closeModal() {
        this.showEditCategoryDialog = false
      },
  
      logout() {
        // remove user from local storage to log user out
        localStorage.removeItem("user");
        location.reload(true);
      },
    },
  };
  </script>
  
  
  <style scoped>
  .home_flex {
    width: 100%;
    /* border: 5px solid orangered; */
  }
  .home_grid {
    width: 100%;
    /* border: 2px solid green; */
    /* display: grid;
    grid-template-columns: 15% 60% 25%; */
  }
  .home_grid  > section{
    /* border: 2px solid red; */
  }
  
  .home_grid > section:first-child {
    width: 15%;
    height: 100vh;
    background-color: #F8F9FA; 
    color: black;
  
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    overflow: auto;
  }
  .home_grid > section:nth-child(2) {
    /* border: 5px solid blue; */
    margin-left: 15%;
    padding-top: 60px;
    width: 60%;
    min-height: 100vh;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto; */
  }

  .home_grid > section:nth-child(3) {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  overflow: auto;
    /* margin-left: 15%; */
    padding-top: 60px;
    width: 25%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
  
  .theTopNavbar {
    position: fixed;
    top: 0px;
    left: 15%;
    right: 25%;
    z-index: 10;
  }
  
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
    .home_grid > section:first-child {
      display: none;
      width: 0px;
      height: 0px;
      padding: 0px;
      position: relative;
    }
    .home_grid > section:nth-child(2) {
      margin-left: 0px;
      padding-top: 50px;
      width: 100%;
    }
  
    .theTopNavbar {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
    }
  }
  
  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
    .home_grid > section:first-child {
      display: none;
      width: 0px;
      height: 0px;
      padding: 0px;
      position: static;
    }
    .home_grid > section:nth-child(2) {
      margin-left: 0px;
      padding-top: 50px;
      width: 100%;
    }
  
    .theTopNavbar {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
    }
  }
  </style>